import qs from "qs";

/**
 * Deixa só os digitos do telefone
 * @param phone
 * @returns {string}
 */
export const clearPhone = (phone) => {
  try {
    return `${phone.replace(/\D/g, "")}`;
  } catch {
    return "";
  }
};

export const shareWhatsapp = (number, text = null) => {
  const query = qs.stringify({ text, phone: clearPhone(number) });
  return `https://api.whatsapp.com/send?${query}`;
};
