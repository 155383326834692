import React, { useState, useCallback } from "react";
import { MdClose } from "react-icons/md";
import { Btn, Wrapper, Actions, ButtonClose } from "./styles";
import LocalStorage from "helpers/LocalStorage";

const CookiesAlert = () => {
  const [isAccepted, setAccept] = useState(
    LocalStorage.get("cookies") || false
  );

  const handleAccept = useCallback(() => {
    LocalStorage.set("cookies", true);
    setAccept(true);
  }, []);

  if (isAccepted) return false;

  return (
    <Wrapper>
      <div>
        <p>
          Esse site utiliza <strong>cookies</strong>, que possibilitam uma
          melhor experiência de navegação. <br /> Ao clicar em continuar, você
          concorda com o uso de <strong>cookies</strong>.
        </p>
      </div>
      <Actions>
        <Btn onClick={handleAccept}>Concordo, continuar</Btn>
        <ButtonClose onClick={handleAccept}>
          <MdClose />
        </ButtonClose>
      </Actions>
    </Wrapper>
  );
};

export default CookiesAlert;
