import styled from "styled-components";
import { Breakpoints } from "config-styles";

export const Wrapper = styled.div`
  display: flex;
  position: relative;
  height: 100vh;
`;

export const Content = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: ${({ size }) => size}px;
  margin: auto;
  width: 100%;
  padding: 30px;
  background-color: #ffffff;
  border-radius: 4px;
`;

export const Close = styled.div`
  position: absolute;
  bottom: 100%;
  left: 100%;
  font-size: 2em;
  color: #fff;
  cursor: pointer;

  ${Breakpoints.tabletMax} {
    left: auto;
    right: 0;
  }
`;
