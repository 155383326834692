import React from "react";
import styled from "styled-components";
import * as PropTypes from "prop-types";
import lock from "./lock.png";
import Button from "components/Button";

const Wrapper = styled.div`
  text-align: center;

  img {
    margin-bottom: 10px;
  }

  h3 {
    margin-bottom: 0.2em;
  }

  p {
    margin-bottom: 15px;
  }
`;

function ModalWebsiteLocked({ days, handleClose }) {
  return (
    <Wrapper>
      <img src={lock} alt="Icone de desbloqueido" />
      <h3>Não foi possível liberar seu site</h3>
      <p>Você utilizou esse recursos há {days} dias.</p>
      <Button onClick={handleClose} kind="solid" color="secondary">
        Entendi
      </Button>
    </Wrapper>
  );
}

ModalWebsiteLocked.defaultProps = {
  days: 5
};

ModalWebsiteLocked.propTypes = {
  days: PropTypes.number
};

export default ModalWebsiteLocked;
