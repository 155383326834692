import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  max-width: ${(p) => p.size || "1180px"};
  margin: 0 auto;
`;

export const Section = styled.section`
  width: 100%;
  padding: 60px 25px;
  background: ${({ color }) => color};

  @media all and (max-width: 40em) {
    padding: 40px 20px;
  }
`;
