import styled from "styled-components";
import { em } from "polished";
import { H2, P } from "components/Typograph";

export const Wrapper = styled.div`
  padding: 0 20px;
  width: 100%;
  text-align: center;
  color: ${props => props.color};
  font-size: ${em("40px")};
  margin-bottom: ${em("50px", "40px")};

  @media all and (max-width: 40em) {
    font-size: ${em("25px")};

    br {
      display: none;
    }
  }
`;

export const Title = styled(H2)`
  color: inherit;
  margin-bottom: ${em("20px", "40px")};

  @media all and (max-width: 40em) {
    font-size: 20px;
    margin-bottom: 15px;
  }
`;

export const Text = styled(P)`
  color: inherit;
  line-height: 30px;

  @media all and (max-width: 40em) {
    font-size: 14px;
  }
`;
