import styled from "styled-components";
import { Link } from "gatsby";
import Signup from "components/Signup";
import { Wrapper as Button } from "components/Button/styles";
import { Wrapper as MainTitle } from "components/MainTitle/styles";
import { Breakpoints } from "../../config-styles";

export const Wrapper = styled.header`
  width: 100%;
  position: fixed;
  z-index: ${({ theme }) => theme.zIndex.header};
  top: 0;
  left: 0;
  transition: background-color 0.3s;
  will-change: background-color;

  background-color: ${({ color }) => color};

  &.isOpen {
    background-color: #ffffff;
  }

  + ${MainTitle} {
    ${Breakpoints.desktop} {
      margin-top: 40px;
    }

    ${Breakpoints.desktopMax} {
      margin-top: 20px;
    }
  }
`;

export const SignupMobile = styled(Signup)`
  margin-right: 15px;
  height: 35px;
  line-height: 35px;
  padding: 0 20px;
  margin-top: -5px;
  font-weight: 600;
`;

export const Container = styled.div`
  display: flex;
  width: 100%;
  max-width: ${({ theme }) => theme.baseWidth};
  margin: 0 auto;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;

  @media all and (max-width: 960px) {
    line-height: 80px;
    height: 80px;
  }
`;

export const Logo = styled.div`
  flex-grow: 1;

  img {
    vertical-align: middle;
  }
`;

export const Menu = styled.nav`
  display: flex;

  @media all and (max-width: 960px) {
    flex-direction: column;
  }

  @media all and (min-width: 960px) {
    margin-right: 25px;
  }
`;

export const MenuLink = styled(Link)`
  position: relative;
  text-decoration: none;
  color: #272727;
  transition: color 0.3s;
  will-change: color;

  &.active {
    color: #0060c3;
  }

  @media all and (min-width: 960px) {
    height: 85px;
    line-height: 85px;
    padding: 0 15px;
    cursor: pointer;

    font-size: 16px;
    font-weight: 500;
    margin-left: 10px;

    &::after {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 4px;
      background: currentColor;
      transform: translateY(-4px);
      opacity: 0;
      transition: transform 0.3s, opacity 0.3s;
      will-change: transform, opacity;
    }

    &.active::after {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @media all and (max-width: 960px) {
    width: 100%;
    padding: 0 20px;
    height: 50px;
    line-height: 50px;
    font-weight: 700;
    font-size: 18px;
  }
`;

export const MenuActions = styled.div`
  @media all and (min-width: 960px) {
    ${Button} + ${Button} {
      margin-left: 20px;
    }
  }

  @media all and (max-width: 960px) {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 40px 20px 20px;

    border-top: 1px solid #ced9eb;
    margin-top: 40px;

    ${Button} {
      text-align: center;
      flex-grow: 1;
      margin-bottom: 20px;
    }
  }
`;

export const MenuWrapper = styled.div`
  @media all and (max-width: 960px) {
    position: fixed;
    top: 80px;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;

    transition: all 0.3s;

    .isOpen & {
      transform: translateX(0);
      opacity: 1;
    }

    .isClosed & {
      transform: translateX(100%);
      opacity: 0;
    }
  }

  @media all and (min-width: 960px) {
    display: flex;
    flex-grow: 1;
    justify-content: flex-end;
    align-items: center;
  }
`;

export const MenuIcon = styled.div`
  flex-shrink: 0;
  font-size: 25px;
  vertical-align: middle;

  @media all and (min-width: 960px) {
    display: none;
  }
`;

export const ShowMobile = styled.span`
  @media all and (min-width: 960px) {
    display: none;
  }
`;
