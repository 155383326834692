import React, { useCallback } from "react";
import * as PropTypes from "prop-types";
import { isMobile } from "is-mobile";
import { navigate } from "gatsby";
import Button from "../Button/index";
import { useModalState } from "containers/ModalProvider";
import { ActionModals } from "containers/ModalRoot/module";

function Signup({
  color = "primary",
  kind = "solid",
  children,
  onOpen,
  className,
}) {
  const [, dispatch] = useModalState();

  const onClick = useCallback(
    (e) => {
      e.preventDefault();

      if (isMobile()) {
        navigate("/cadastro");
        return null;
      }

      dispatch(ActionModals.openModalSignup());

      if (onOpen) onOpen();
    },
    [dispatch, onOpen]
  );

  return (
    <Button
      as="a"
      role="button"
      target="_blank"
      rel="noopener noreferrer"
      color={color}
      kind={kind}
      onClick={onClick}
      className={className}
    >
      {children}
    </Button>
  );
}

Signup.defaultProps = {
  children: "Testar grátis",
};

Signup.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

export default Signup;
