import React, {useEffect} from "react";
import styled from "styled-components";
import { string } from "prop-types";
// Components
import problem from "./problem.svg";
import Button from "../../../components/Button";
import {Link} from "../../../components/Typograph";
import {useModalState} from "../../ModalProvider";
import {Actions as ActionsModal} from "../module";

const Wrapper = styled.div`
  text-align: left;

  img {
    margin-bottom: 10px;
  }

  h3 {
    margin-bottom: 0.2em;
  }

  p {
    margin-bottom: 15px;
  }
`;

const Image = styled.img`
  width: 80px;
  margin-right: 30px;
`;

const Actions = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 10px;
`

const Content = styled.div`
  display: flex;
  align-items: flex-start;
`

const Inner = styled.div`
  
`

function ModalEmergence() {
  const [, dispatch] = useModalState();

  useEffect(() => {
    localStorage.setItem('@tecimob/emergecy', true)
  }, []);

  return (
    <Wrapper>
      <Content>
        <Image src={problem} alt="Problema" />
        <Inner>
          <h3>
            Atenção
          </h3>
          <p>
            Nossa central de atendimento via WhatsApp está temporariamente fora de serviço. Caso precise conversar com nossa equipe, por favor, clique no ícone de atendimento no canto inferior da tela, que abrirá um chat interno diretamente aqui no seu navegador de internet.

            Pedimos desculpas pelo transtorno e agradecemos pela compreensão.
          </p>
        </Inner>
      </Content>
      <Actions>
        <Button color="secondary" kind="border" onClick={() => {
          dispatch(ActionsModal.close())
        }}>Fechar</Button>
      </Actions>
    </Wrapper>
  );
}

ModalEmergence.defaultProps = {
  dateEnd: null,
  urlSite: null
};

ModalEmergence.propTypes = {
  dateEnd: string,
  urlSite: string
};

export default ModalEmergence;
