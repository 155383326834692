import React, { useEffect } from "react";
import PropTypes from "prop-types";
import "intersection-observer";
// Components
import Content from "./Content";
// Containers
import { setReferrerSource } from "helpers/referrer";
// Static Queries
import "./staticQueries";
import { useModalState } from "../../containers/ModalProvider";
import { ActionModals } from "../../containers/ModalRoot/module";

function Layout({ headerColor, children }) {
  const [, dispatch] = useModalState();

  useEffect(() => {
    setReferrerSource(window.location.search);
  }, []);

  const handleChannelSession = () => {
    const channel = new URLSearchParams(window.location.search).get("channel");

    if (channel) {
      localStorage.setItem("@tecimob/channel", channel);
      return false;
    }
  };

  useEffect(() => {
    handleChannelSession();

    // if (!localStorage.getItem("@tecimob/emergecy")) {
    //   dispatch(ActionModals.openModalEmergency())
    // }
  }, []);

  return <Content headerColor={headerColor}>{children}</Content>;
}

Layout.defaultProps = {
  headerColor: "#fff",
  children: null,
};

Layout.propTypes = {
  children: PropTypes.node,
};

export default Layout;
