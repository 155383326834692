import styled from "styled-components";
import BaseHeading from "./BaseHeading";

const H2 = styled(BaseHeading).attrs(({ as }) => ({
  as: as || "h2"
}))`
  font-size: 40px;
`;

export default H2;
