import styled from "styled-components";
import BaseHeading from "./BaseHeading";

const H1 = styled(BaseHeading).attrs(() => ({
  as: "h1"
}))`
  font-size: 44px;
`;

export default H1;
