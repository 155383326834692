import React from "react";
import { useModalState } from "../ModalProvider";
import Modal from "components/Modal";
import { Actions } from "./module";
import ModalWebsiteLocked from "./ModalWebsiteLocked";
import ModalWebsiteUnlocked from "./ModalWebsiteUnlocked";
import ModalFormCallYou from "./ModalFormCallYou";
import ModalEmergency from "./ModalEmergency";
import ModalEvent from "./ModalEvent";
import ModalSignup from "./ModalSignup";
import ModalPortalConditions from "./ModalPortalConditions";
import ModalImplementation from "./ModalImplementation";

export const MODALS = {
  ModalWebsiteLocked,
  ModalWebsiteUnlocked,
  ModalFormCallYou,
  ModalEmergency,
  ModalEvent,
  ModalSignup,
  ModalPortalConditions,
  ModalImplementation,
};

function ModalRoot() {
  const [{ modal, modalProps }, dispatch] = useModalState();
  if (!modal) return null;
  const SpecificModal = MODALS[modal];

  return (
    <Modal
      size={modalProps?.size}
      isOpen
      handleClose={() => dispatch(Actions.close())}
    >
      <SpecificModal
        {...modalProps}
        handleClose={() => dispatch(Actions.close())}
      />
    </Modal>
  );
}

export default ModalRoot;
