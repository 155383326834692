import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  img {
    margin-bottom: 10px;
  }

  p {
    margin-bottom: 15px;
  }
`;

const Title = styled.h3`
  margin-bottom: 10px;
`;

const Subtitle = styled.h4`
  margin-bottom: 5px;
  margin-top: 15px;
  font-size: 14px;
`;

const List = styled.ul`
  margin: 5px;
  list-style: disc inside;

  li + li {
    margin-top: 5px;
  }

  span {
    margin-left: -5px;
  }
`;

function ModalPortalConditions() {
  return (
    <Wrapper>
      <Title>Termos e condições</Title>
      <Subtitle>Condições</Subtitle>
      <List>
        <li>
          <span>
            A oferta é válida somente para novos clientes do Imovelweb.
          </span>
        </li>
        <li>
          <span>
            A oferta não se aplica aos estados de São Paulo, Distrito Federal e
            Curitiba.
          </span>
        </li>
      </List>
      <Subtitle>Oferta</Subtitle>
      <p>O portal oferece gratuidade de anúncios para as seguintes regiões:</p>
      <List>
        <li>
          <span>
            50 Anúncios sub-simples por 06 meses ( cidades de Balneário Camboriú,
            Florianópolis e Salvador);
          </span>
        </li>
        <li>
          <span>
            200 Anúncios sub-simples por 12 meses ( nos estados BA, SC, MG, PR e RJ
            exceto cidades acima);
          </span>
        </li>
        <li>
          <span>
            500 Anúncios sub-simples por 12 meses nas demais regiões não citadas
            acima;
          </span>
        </li>
        <li>
          <span>
            1.000 Anúncios sub-simples por 12 meses no estado do Rio Grande do Sul.
          </span>
        </li>
      </List>
      <Subtitle>Considerações</Subtitle>
      <List>
        <li>
          <span>
            Clientes serão considerados novos quando nunca tiverem conta no
            Imovelweb ou estiverem a mais de 06 meses cancelados.
          </span>
        </li>
        <li>
          <span>
            Esta oferta é fornecida pelo portal Imovelweb para clientes Tecimob,
            cabendo ao portal o direito de mudança ou cancelamento do benefício
            a qualquer momento.
          </span>
        </li>
        <li>
          <span>
            Dúvidas sobre gratuidade podem ser esclarecidas diretamente com
            portal através do e-mail atendimento@imovelweb.com.br ou ligar para
            (11) 4097- 5660. Outras Regiões 4003-9048
          </span>
        </li>
      </List>
    </Wrapper>
  );
}

export default ModalPortalConditions;
