import styled from "styled-components";
import Button from "components/Button";
import { Breakpoints } from "config-styles";

export const Wrapper = styled.div`
  padding: 8px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  bottom: 60px;
  left: 50%;
  background-color: #30303e;
  width: 90%;
  max-width: 1110px;
  transform: translateX(-50%);
  border-radius: 4px;
  z-index: 10;

  ${Breakpoints.tabletMax} {
    flex-direction: column;
    align-items: flex-start;
  }

  p {
    color: #fff;
    line-height: 24px;

    ${Breakpoints.tabletMax} {
      br {
        display: none;
      }
    }
  }
`;

export const Actions = styled.div`
  display: flex;

  ${Breakpoints.tabletMax} {
    margin-top: 15px;
  }
`;
export const Btn = styled(Button).attrs(() => ({
  color: "white",
  kind: "border"
}))`
  font-size: 14px;
  padding: 0 15px;
  line-height: 40px;
  font-weight: 700;
`;

export const ButtonClose = styled.button.attrs(() => ({
  'aria-label': 'Fechar'
}))`
  display: grid;
  place-items: center;
  width: 40px;
  height: 40px;
  color: #fff;
  margin-left: 15px;
  font-size: 25px;
  cursor: pointer;
  background-color: transparent;
  border: 0 none;
`;
