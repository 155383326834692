import React, {useEffect, useState} from "react";
import { FaFacebook, FaInstagram, FaLinkedinIn } from "react-icons/fa";
// Components
import {
  Wrapper,
  Content,
  Copyright,
  Divider,
  Box,
  Logo,
  ListSocial,
} from "./styles";
// Assets
// import logoTecimob from "images/logo-tecimob.svg";

function Footer() {
  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    if (!isInitialized) {
      const script = document.createElement("script");
      script.src = "https://s3.amazonaws.com/raichu-beta/ra-verified/bundle.js";
      script.id = "ra-embed-verified-seal";
      script.setAttribute("data-id", "VE45ckhITm8tbTRfenRUaTp0ZWNpbW9i");
      script.setAttribute("data-target", "ra-verified-seal");
      script.setAttribute("data-model", "2");
      document.getElementById("ra-verified-seal").appendChild(script);
    }

    setIsInitialized(true);
  }, [isInitialized]);

  return (
    <Wrapper>
      <Content>
        <Box>
          <div id="ra-verified-seal" className="logo-reclame-aqui" />
          {/*<Logo src={logoTecimob} alt="Logo Tecimob"/>*/}
          <Copyright>
            © 2020 - Tecimob | Santa Catarina - CNPJ 15.512.782/0001-15
          </Copyright>
        </Box>
        <Box>
          <a
            href="https://tecimob.com.br/termos-de-uso"
            target="_blank"
            rel="noopener noreferrer"
          >
            Termos de uso
          </a>
          <Divider>|</Divider>
          <a
            href="https://tecimob.com.br/politica-privacidade"
            target="_blank"
            rel="noopener noreferrer"
          >
            Politica de privacidade
          </a>
          <Divider>|</Divider>
          <ListSocial>
            <li>
              <a
                href="https://www.instagram.com/tecimob/"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Instagram do tecimob"
              >
                <FaInstagram />
              </a>
            </li>
            <li>
              <a
                href="https://www.facebook.com/tecimob"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Facebook do tecimob"
              >
                <FaFacebook />
              </a>
            </li>
            <li>
              <a
                href="https://www.linkedin.com/company/tecimob"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Linkedin do tecimob"
              >
                <FaLinkedinIn />
              </a>
            </li>
          </ListSocial>
        </Box>
      </Content>
    </Wrapper>
  );
}

export default Footer;
