import styled from "styled-components";
import { rgba } from "polished";
import { Container } from "components/Layout/styles";
import { H3 } from "components/Typograph";
import { Breakpoints } from "config-styles";

export const Wrapper = styled.footer`
  display: flex;
  flex-direction: column;
  padding: 0 20px;

  ${H3} {
    font-size: 16px;
    margin-bottom: 15px;
  }

  @media all and (max-width: 40em) {
    text-align: center;
  }
`;

export const Content = styled(Container)`
  padding: 30px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${Breakpoints.tabletMax} {
    flex-direction: column;
  }
`;

export const Copyright = styled.span`
  font-size: 14px;
  margin-left: 30px;
`;

export const Box = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  ${Breakpoints.tabletMax} {
    flex-direction: column;
    margin-bottom: 15px;
    
    .logo-reclame-aqui {
      margin-bottom: 15px;
    }
  }

  a {
    color: ${p => p.theme.colors.secondary};
  }
`;

export const Divider = styled.span`
  margin: 0 15px;

  ${Breakpoints.tabletMax} {
    text-indent: -9999px;
  }
`;

export const ListSocial = styled.ul`
  li {
    display: inline-block;

    & + li {
      margin-left: 10px;
    }
  }

  svg {
    vertical-align: middle;
  }

  a {
    border-radius: 2px;
    display: inline-block;
    width: 25px;
    height: 25px;
    line-height: 25px;
    text-align: center;
    color: ${({ theme }) => theme.colors.secondary};
    background: ${({ theme }) => rgba(theme.colors.secondary, 0.2)};
  }
`;

export const Logo = styled.img`
  font-size: 14px;

  ${Breakpoints.tabletMax} {
    margin-bottom: 15px;
  }
`;
