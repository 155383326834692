import React, { useMemo } from "react";
import { ThemeProvider } from "styled-components";
import { MdClose } from "react-icons/md";
import {
  Wrapper,
  Box,
  BoxContent,
  WrapImage,
  Image,
  Title,
  Text,
  WrapArrow,
  Arrow,
  WrapIconChat,
  IconChat,
  Close,
} from "./styles";
import { IMAGES } from "components/ChatBox/constants";
import { getRandomInt } from "helpers/helpers";
import { shareWhatsapp } from "helpers/share-helpers";
import {WHATSAPP_NUMBER_FORMATTED} from "../../constants";

const ChatBox = ({ isOpen = true, isBoxOpen = true, handleClose }) => {
  const imageSrc = useMemo(() => {
    return IMAGES[getRandomInt(0, IMAGES.length)];
  }, []);

  return (
    <ThemeProvider
      theme={{
        isBoxOpen,
      }}
    >
      {isOpen && (
        <>
          <Box>
            <BoxContent>
              <WrapImage>
                <Image src={imageSrc} alt="Image" />
              </WrapImage>
              <Title>Estamos aqui para te ajudar!</Title>
              <Text>
                Caso queira falar com um de nossos especialistas, clique aqui.
              </Text>
              <Close onClick={handleClose}>
                <MdClose />
              </Close>
              <a target="_blank" href={shareWhatsapp(WHATSAPP_NUMBER_FORMATTED)} />
            </BoxContent>
          </Box>
          <WrapArrow>
            <Arrow src="/chat/icon-arrow.svg" alt="Icon Arrow" />
          </WrapArrow>
        </>
      )}
      <Wrapper>
        <WrapIconChat>
          <IconChat target="_blank" href={shareWhatsapp(WHATSAPP_NUMBER_FORMATTED)}>
            <img src="/chat/whatsapp.svg" alt="Icon Chat" />
          </IconChat>
        </WrapIconChat>
      </Wrapper>
    </ThemeProvider>
  );
};

export default ChatBox;
