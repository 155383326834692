import React from "react";
import styled from "styled-components";
import SignupForm from "components/SignupForm";

const Wrapper = styled.div`
  img {
    margin-bottom: 10px;
  }

  h3 {
    margin-bottom: 0.2em;
  }

  p {
    margin-bottom: 15px;
  }
`;

export default function ModalSignup() {
  return (
    <Wrapper>
      <SignupForm />
    </Wrapper>
  );
}
