import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  img {
    margin-bottom: 10px;
  }

  p {
    margin-bottom: 15px;
  }
`;

const Title = styled.h3`
  margin-bottom: 10px;
`;

const Subtitle = styled.h4`
  margin-bottom: 5px;
  margin-top: 15px;
  font-size: 14px;
`;

const List = styled.ul`
  margin: 5px;
  list-style: disc inside;

  li + li {
    margin-top: 5px;
  }

  span {
    margin-left: -5px;
  }
`;

function ModalPortalConditions() {
  return (
    <Wrapper>
      <Title>Implantação completa</Title>
      <Subtitle>Condições</Subtitle>
      <List>
        <li>
          <span>
            Auxílio nas configurações iniciais;
          </span>
        </li>
        <li>
          <span>
            Suporte na migração de dados (a migração deve seguir o padrão XML solicitado ou ser proveniente de softwares compatíveis);
          </span>
        </li>
        <li>
          <span>
            Apoio na configuração de integrações;
          </span>
        </li>
        <li>
          <span>
            Demonstração de recursos úteis adaptados às necessidades do corretor;
          </span>
        </li>
        <li>
          <span>
            Acompanhamento nos primeiros dias de uso.
          </span>
        </li>
      </List>
    </Wrapper>
  );
}

export default ModalPortalConditions;
