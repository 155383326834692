import React from "react";
import PropTypes from "prop-types";
import Modal from "react-modal";
import { IoIosCloseCircle as CloseIcon } from "react-icons/io";
import { Wrapper, Content, Close } from "./styles";

Modal.setAppElement(`#___gatsby`);

const styles = {
  overlay: {
    position: `fixed`,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: `rgba(0, 0, 0, 0.75)`,
    zIndex: 9999
  },
  content: {
    position: `absolute`,
    border: `none`,
    background: `none`,
    padding: 0,
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    overflow: `auto`,
    WebkitOverflowScrolling: `touch`,
    borderRadius: "4px"
  }
};

function MyModal({ size, children, isOpen, handleClose }) {
  return (
    <Modal
      isOpen={isOpen}
      style={styles}
      contentLabel="Modal"
      closeTimeoutMS={9000}
      handleClose={handleClose}
    >
      <Wrapper>
        <Content size={size}>
          <Close onClick={handleClose}>
            <CloseIcon />
          </Close>
          {children}
        </Content>
      </Wrapper>
    </Modal>
  );
}

MyModal.defaultProps = {
  size: 350,
  children: null,
  isOpen: false,
  handleClose: null
};

MyModal.propTypes = {
  size: PropTypes.number,
  children: PropTypes.node,
  isOpen: PropTypes.bool,
  handleClose: PropTypes.func
};

export default MyModal;
