import React from "react";
import styled from "styled-components";
import powerClass from "./powerclass.png";
import powerclassMobile from "./powerclass-mobile.png";

export const Wrapper = styled.div`
  width: calc(100% + 60px);
  margin-left: -30px;
  margin-right: -30px;
  margin-top: -30px;
  margin-bottom: -45px;

  img {
    margin-bottom: 10px;
  }

  h3 {
    margin-bottom: 0.2em;
  }

  p {
    margin-bottom: 15px;
  }
`;

const Link = styled.a`
  text-align: center;
`;

const Image = styled.img`
  width: 100%;
  max-height: 85vh;
`;

export default function ModalEvent({ handleClose }) {
  return (
    <Wrapper>
      <Link
        href="https://novidades.ibrep.com.br/powerclass-vivendodeimoveis?utm_source=parceiros&utm_medium=post&utm_campaign=tecimob"
        target="_blank"
        rel="noreferrer noopener"
        onClick={handleClose}
      >
        <picture>
          <source media="(max-width: 768px)" srcSet={powerclassMobile} />
          <source media="(min-width: 769px)" srcSet={powerClass} />
          <Image src={powerClass} alt="Banner" />
        </picture>
      </Link>
    </Wrapper>
  );
}
