import React from "react";
import styled from "styled-components";
import { string } from "prop-types";
// Components
import Button from "components/Button";
import unlock from "./unlock.png";

const Wrapper = styled.div`
  text-align: center;

  img {
    margin-bottom: 10px;
  }

  h3 {
    margin-bottom: 0.2em;
  }

  p {
    margin-bottom: 15px;
  }
`;

function ModalWebsiteUnlocked({ dateEnd, urlSite }) {
  return (
    <Wrapper>
      <img src={unlock} alt="Icone de desbloqueido" />
      <h4>Seu site foi liberado por 3 dias</h4>
      <p>Até {dateEnd}</p>
      <Button
        kind="solid"
        color="secondary"
        as="a"
        href={urlSite}
        target="_blank"
        rel="noopener noreferrer"
      >
        Ver site
      </Button>
    </Wrapper>
  );
}

ModalWebsiteUnlocked.defaultProps = {
  dateEnd: null,
  urlSite: null
};

ModalWebsiteUnlocked.propTypes = {
  dateEnd: string,
  urlSite: string
};

export default ModalWebsiteUnlocked;
