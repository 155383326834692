import styled, { keyframes, css } from "styled-components";

const shake = keyframes`
  from {
    transform: rotate(15deg) translateX(10px);
  }
  
  to {
    transform: rotate(25deg) translateX(0);
  }
`;

export const Box = styled.div`
  width: 270px;
  bottom: 75px;
  right: 20px;
  position: fixed;
  padding: 20px;
  background: #ffffff;
  border: 1px solid #d3dceb;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  border-radius: 4px;

  opacity: 0;
  visibility: hidden;
  transition: all 0.2s ease-in-out;
  z-index: 999;

  ${(p) =>
    p.theme.isBoxOpen &&
    css`
      opacity: 1;
      visibility: visible;
    `};

  @media all and (max-width: 1024px) {
    display: none;
  }
`;

export const BoxContent = styled.div`
  position: relative;

  a {
    position: absolute;
    top: 21px;
    left: -20px;
    width: calc(100% + 40px);
    height: 100%;
  }
`;

export const Close = styled.button`
  position: absolute;
  width: 30px;
  height: 30px;
  display: grid;
  place-items: center;
  background-color: #fff;
  border-radius: 50%;
  top: 9px;
  right: -35px;
  border: 1px solid #d3dceb;
  z-index: 5;
`;

export const WrapArrow = styled.div`
  position: fixed;
  bottom: 33px;
  right: 72px;
  width: 75px;
  height: 78px;
  animation: ${shake} 0.5s infinite ease-in-out;
  animation-direction: alternate;
  z-index: 100;

  @media all and (max-width: 1024px) {
    display: none;
  }
`;

export const WrapImage = styled.div`
  position: relative;
  width: 56px;
  height: 56px;
  border: 3px solid #31d084;
  border-radius: 50%;
  margin: -45px auto 10px;

  &:after {
    content: "";
    display: inline-block;
    position: absolute;
    bottom: 0;
    right: 0;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #f84343;
  }
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
`;
export const Title = styled.div`
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 5px;
`;
export const Text = styled.div``;

export const Arrow = styled.img`
  width: 75px;
  height: 78px;
  opacity: 0;
  transition: all 0.1s ease-in-out;
  transform: translateY(-50px);
  pointer-events: none;

  ${(p) =>
    p.theme.isBoxOpen &&
    css`
      opacity: 1;
      transform: translateY(0) rotate(0);
      transition: all 0.3s ease-in-out;
    `}
`;
export const IconChat = styled.a`
  width: 50px;
  height: 50px;
  background: #31d084;
  border-radius: 50%;
  display: grid;
  place-items: center;
  margin: 15px 0;
  cursor: pointer;
`;

export const WrapIconChat = styled.div`
  position: relative;
  display: flex;
  align-items: flex-end;
`;

export const Wrapper = styled.div`
  position: fixed;
  bottom: 0;
  right: 20px;
  width: 100%;
  max-width: 50px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  z-index: 100;

  @media all and (max-width: 768px) {
    display: none;
  }
`;
