import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import { MdClose, MdMenu } from "react-icons/md";
// Styles
import {
  Wrapper,
  MenuWrapper,
  Container,
  Menu,
  MenuLink,
  MenuActions,
  Logo,
  MenuIcon,
  ShowMobile,
  SignupMobile,
} from "./styles";
// Helpers
import { throttle } from "helpers/function-helpers";
// Assets
import Tecimob from "images/logo-tecimob.svg";
import Signup from "../Signup/index";
import Signin from "../Signin/index";

const defaultProps = {
  color: "#fff",
};

const propTypes = {
  color: PropTypes.string,
};

function MainHeader({ color }) {
  const [isOpen, setVisibility] = useState(false);
  const [isScrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.pageYOffset < 20) setScrolled(false);
      if (window.pageYOffset > 20) setScrolled(true);
    };

    const onScroll = throttle(handleScroll, 5);

    if (color !== "#fff") {
      window.addEventListener("scroll", onScroll, false);
    }

    // Especifique como limpar depois desse efeito:
    return () => {
      window.removeEventListener("scroll", onScroll, false);
    };
  }, [color]);

  return (
    <>
      <div style={{ height: 80 }} />
      <Wrapper
        color={isScrolled ? "#fff" : color}
        className={isOpen ? "isOpen" : "isClosed"}
      >
        <Container>
          <Logo as={Link} to="/">
            <img
              src={Tecimob}
              alt="Logo Tecimob - Empresa de sites para imobiliárias e corretores de imóveis"
            />
          </Logo>
          <MenuWrapper>
            <Menu>
              <MenuLink to="/" activeClassName="active">
                Início
              </MenuLink>
              <MenuLink to="/modelos/" activeClassName="active">
                Modelos
              </MenuLink>
              <MenuLink to="/planos/" activeClassName="active">
                Planos
              </MenuLink>
              <MenuLink
                as="a"
                href="https://tecimob.com.br/blog/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Blog
              </MenuLink>
            </Menu>
            <MenuActions>
              <Signup>
                Testar <ShowMobile>Grátis</ShowMobile>
              </Signup>
              <Signin>
                Entrar <ShowMobile>no sistema</ShowMobile>
              </Signin>
            </MenuActions>
          </MenuWrapper>
          <ShowMobile>
            <SignupMobile>Testar</SignupMobile>
          </ShowMobile>
          <MenuIcon
            onClick={() => {
              setVisibility(!isOpen);
            }}
          >
            {isOpen ? <MdClose /> : <MdMenu />}
          </MenuIcon>
        </Container>
      </Wrapper>
    </>
  );
}

MainHeader.defaultProps = defaultProps;
MainHeader.propTypes = propTypes;

export default MainHeader;
