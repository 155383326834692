import styled from "styled-components";
import BaseHeading from "./BaseHeading";

const H4 = styled(BaseHeading).attrs(() => ({
  as: "h4"
}))`
  font-size: 20px;
`;

export default H4;
