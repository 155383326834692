import React, { useState, useEffect } from "react";
import ChatBox from "components/ChatBox";
import { isChatVisible } from "components/ChatBox/helpers";
import useLocalStorage from "hooks/useLocalStorage";

function ContactBar() {
  const [isVisible, setIsChatVisible] = useState(false);
  const [isOpen, setIsOpen] = useLocalStorage("@tecimob/popover", true);

  const [scrollTop, setScrollTop] = useState(0);

  useEffect(() => {
    if (isChatVisible(new Date())) {
      setIsChatVisible(true);
    }
  }, []);

  useEffect(() => {
    const onScroll = (e) => {
      setScrollTop(e.target.documentElement.scrollTop);
    };

    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, [scrollTop]);

  return (
    <ChatBox
      isOpen={isOpen}
      isBoxOpen={scrollTop > 50}
      handleClose={() => setIsOpen(false)}
    />
  );
}

export default ContactBar;
