import styled from "styled-components";
import BaseHeading from "./BaseHeading";

const H3 = styled(BaseHeading).attrs(() => ({
  as: "h3"
}))`
  font-size: 35px;
`;

export default H3;
