import React, { useEffect } from "react";
// Components
import MainHeader from "components/MainHeader";
import Footer from "components/Footer";
import CookiesAlert from "components/CookiesAlert";
import ContactBar from "components/ContactBar";
// Containers
import { useModalState } from "containers/ModalProvider";
import ModalRoot from "containers/ModalRoot";
// Helpers
import { getUrlParams, clearSearchParams } from "helpers/url-helpers";
// Modules
import { Actions } from "../../containers/ModalRoot/module";

function Content({ headerColor, children }) {
  const [, dispatch] = useModalState();

  useEffect(() => {
    const { modal, props } = getUrlParams();

    if (modal) {
      // Limpa os parametros da url
      clearSearchParams();

      dispatch(
        Actions.open({
          modal,
          props,
        })
      );
    }
  }, [dispatch]);

  return (
    <>
      <MainHeader color={headerColor} />
      {children}
      <Footer />
      <ContactBar />
      <ModalRoot />
      <CookiesAlert />
    </>
  );
}

export default Content;
