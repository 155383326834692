/**
 * Melhora a chamada do callBack
 * é bom pra quando a função é chamada várias veses
 * @param {Function} callBack
 * @param {Number} wait
 * @returns {Function}
 */
export function throttle(callBack, wait = 5) {
  let time = Date.now();
  return function(...props) {
    if (time + wait - Date.now() < 0) {
      callBack(...props);
      time = Date.now();
    }
  };
}
