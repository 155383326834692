const today = new Date();

/**
 * Verifica se é dia de semana
 * retorna true caso seja
 * @param date
 * @return {boolean}
 */
export const isWeekDay = (date) => {
  const day = date.getDay();
  return day >= 1 && day <= 5;
};

/**
 * Verifica a hora de funcionamento do chat
 * @param date
 * @return {boolean}
 */
export const checkAvaliableHours = (date) => {
  // 8:15
  const start = 8 * 60 + 15;

  // 17:45
  const end = 17 * 60 + 45;
  const now = date.getHours() * 60 + date.getMinutes();

  return start <= now && now <= end;
};

/**
 * Verifica se pode mostrar o chat
 * retorna true quando pode mostrar o chat
 * @param date
 * @return {boolean}
 */
export const isChatVisible = (date = today) => {
  return isWeekDay(date) && checkAvaliableHours(date);
};
